import './App.css';
import PurchasePage from "./pages/PurchasePage";
import React, { useEffect } from 'react';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import SuccessPage from "./pages/SuccessPage";
import './utils/iNoBounce.min';
import {CartProvider} from "./contexts/reducers";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import RangePage from './pages/RangePage';
import { CurrencyProvider } from './contexts/CurrencyProvider';
import { TenantProvider } from './contexts/TenantProvider';
import ThankYouPage from './pages/ThankYouPage';
import { CookiesProvider } from 'react-cookie';
import { ConsentCookiesProvider } from './contexts/CookiesProvider';
import ManageReservationPage from './pages/ManageReservationPage';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

const App = () => {

    const growthbook = new GrowthBook({
        apiHost: "https://growthbookapi.bookit.one",
        clientKey: "sdk-PC9YPnTXVM9meEFS",
        enableDevMode: true,
        trackingCallback: (experiment, result) => {
        }
      });
      
      useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook.init({ streaming: true });
      }, []);

    return (
        <div className="App px-0 bg-white">
               <GrowthBookProvider growthbook={growthbook}>
                <CookiesProvider>
                <ConsentCookiesProvider>

                <CookieBanner/>
                <CartProvider>
                <CurrencyProvider>
                <BrowserRouter>
                <TenantProvider>
                    <Routes>
                        <Route path="/order/completed" element={<ThankYouPage/>} />
                        <Route path="/order/manage" element={<ManageReservationPage/>}/>
                        <Route path="/order/finished" element={<SuccessPage/>} />
                        <Route  path="/range/type/:rangeType" element={<RangePage/>} />
                        <Route  path="/range/:sku" element={<RangePage/>} />
                        <Route  path="/range" element={<RangePage/>} />
                        <Route  path="/:orderType/:sku" element={<PurchasePage/>} />
                        <Route  path="/:orderType" element={<PurchasePage/>} />
                        <Route  path="/" element={<PurchasePage/>} />    
                    </Routes>
                </TenantProvider>
                </BrowserRouter>
                </CurrencyProvider>
                </CartProvider>
            </ConsentCookiesProvider>
            </CookiesProvider>
            </GrowthBookProvider>
        </div>
    );
}

export default App;
