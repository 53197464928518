import React from 'react';
import './Range.scss';

import Skeleton from 'react-loading-skeleton';


export const SkeletonRangeView = () => {

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <React.Fragment>
            <div className={"product-card mx-0 pb-4 lg:mb-4 mb-4 border-b border-grey-300 "}>
                <div className="md:hidden w-full p-0 min-h-100px">
                    <div className="product-desc-top row p-6">
                        <div className="w-full px-0 text-center" 
                        >
                             <Skeleton height={150} width={120} className='product-image w-auto max-h-150 lg:max-h-200 my-auto mx-auto justify-center' />
                        </div>
                        <div className={"mt-2"}>
                        <div className="text-lg  w-full sm:w-4/6  cursor-default font-semibold leading-4" style={{height: '2rem'}}>
                        <Skeleton count={1} width={120} />
                        </div>
                        <div className="flex">
                        <div className=" w-full">
                            <Skeleton count={1} width={120} />
                        </div>        
                        <div className={"add-to-cart-mobile w-full text-right"}>
                        <Skeleton count={1} width={140} height={40}/>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="hidden md:flex w-full pl-8 p-4">
                    <div className="w-3/4 border-r-2 border-solid">
                        <div className="package-name text-xl font-bold">
                        <Skeleton count={1} width={200}/>
                        </div>
                        <div className="flex w-full">
                            <div>
                            <Skeleton height={300} width={200} className='max-w-full max-h-full block md-4 object-contain mt-4' />
                            </div>
                            <div className="mt-2 px-4 pt-2 w-full" >
                                <Skeleton count={5} />
                                </div>
                        </div>
                    </div>
                    <div className="w-1/4">
                        <div className="text-4xl  text-right mt-8 ml-2">
                        <Skeleton count={1} />
                        </div>
                        <div className="mx-2 w-full text-4xl mt-6">
                        <Skeleton count={1} />
                            
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SkeletonRangeView;
