import {CalendarDay, CalendarTime} from "../../models/CalendarDay.model";
import {TimeSlot} from "../../models/TimeSlot.model";
import {addLocale} from "primereact/api";
import './CalendarSelector.scss';
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import {DEFAULT_DATE_FORMAT, inverseDatesInBounds, isSameDayInStringAndDate, parseDate} from "../../utils/DateUtils";
import {Calendar} from "primereact/calendar";
import classNames from "classnames";
import { CartContext } from "../../contexts/reducers";
import { calendarDe, calendarEn, calendarPl } from "../../utils/CalendarLocale";

type DateTimeSelectorProps = {
    calendarDays?: CalendarDay[],
    selectedTimeSlot?: TimeSlot,
    onDateTimeSelected: (date: string, time: string, calendarId: string) => void
    forceSingle: boolean,
    skipHalfHours: boolean,
    preSelectedSlot?: {date: Date, time:string}|null,
    onMonthUpdated: (date: Date, single: boolean) => void,
    viewDate: Date
}

const DateTimeSelector = (props: DateTimeSelectorProps) => {
    const {state} = useContext(CartContext);
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    const [selectedDate, setSelectedDate] = useState<Date>(today)
    const [minDate, setMinDate] = useState<Date>(new Date())
    const [maxDate, setMaxDate] = useState<Date>(new Date())
    const [disabledDays, setDisabledDays] = useState<Date[]>([])
    const [viewDate, setViewDate] = useState(tomorrow)
    const timeSlotsRef = useRef(null)
    addLocale('pl', calendarPl)
    addLocale('en', calendarEn)
    addLocale('de', calendarDe)

    useEffect(() => {
        if (props.calendarDays && props.calendarDays.length > 0) {
            const dates = props.calendarDays.map(elem => moment(elem.date))
            const min: moment.Moment = moment.min(dates)
            const max: moment.Moment = moment.max(dates)

            const today = new Date()
            setMinDate(min.isBefore(today, "day") ? today : min.toDate())
            setMaxDate(max.toDate())
            if (props.calendarDays.length > 0) {
                setMinDate(new Date(dates[0].toDate()))
                if (!props.preSelectedSlot) {
                    if (selectedDate.toISOString() == tomorrow.toISOString()){
                        setSelectedDate(new Date(dates[0].toDate()))
                    }
                    setViewDate(new Date(dates[0].toDate()))
                }
            }
            const disabled = inverseDatesInBounds(dates, min, max).map(elem => elem.toDate())
            if (props.preSelectedSlot) {
                const momentSelectedSlot = moment(props.preSelectedSlot.date)
                if (momentSelectedSlot.isAfter(min) && momentSelectedSlot.isBefore(max) && !disabled.find(x => momentSelectedSlot.isSame(moment(x), 'day'))) {
                    setSelectedDate(momentSelectedSlot.toDate())
                    setViewDate(momentSelectedSlot.toDate())
                }
            }
            setDisabledDays(disabled)
        }
    }, [props.calendarDays, props.preSelectedSlot])
    useEffect(() => {
        setViewDate(props.viewDate)
    }, [props])
    useEffect(() => {
        if (props.selectedTimeSlot && props.selectedTimeSlot.type === 'timeslot') {
            const date = parseDate(props.selectedTimeSlot.day).toDate()
            setSelectedDate(date)
        }
        
    }, [props.selectedTimeSlot])
    const groupAvailability = (time: CalendarTime[]): CalendarTime[][] => {
        const result = []
        const halfHours = ["00", "30"]
        let counter = 0;
        for (let i = 0; i < 24; i++) {
            result.push(Array<CalendarTime>())
            for (let j = 0; j < (props.skipHalfHours ? 1: 2); j++) {
                const foundTime = time.find(x => x.time === String(i).padStart(2, '0') + ":" + halfHours[j])
                if (foundTime) {
                    foundTime.available = true
                    result[counter].push(foundTime)
                } else {
                    result[counter].push({time: i + ":" + halfHours[j], calendarId: '', available: false})
                }
            }
            counter += 1
        }
        return result
    }
    const availableTimesInSelectedDay: () => CalendarTime[][] = useCallback(() => {
            const data = props.calendarDays?.find(elem => isSameDayInStringAndDate(moment(elem.date), moment(selectedDate)))
            if (data) {
                const result = groupAvailability(data.times)
                const available = result.filter(x => x.filter(time => time.available).length > 0)
                const first = available[0]
                const last = available[available.length - 1]
                const firstindex = result.indexOf(first)

                const lastIndex = result.indexOf(last)
                const results = result.slice(firstindex, lastIndex +1)
                if (props.preSelectedSlot) {
                   const selectedTime = props.preSelectedSlot.time
                   const hourArray = results.find( x => x.find(y => y.time === selectedTime && y.available === true))
                   const slot = hourArray?.find(y => y.time === selectedTime && y.available === true)
                   if (slot) {
                    setTimeout(() => {
                        handleTimeClick(selectedDate, slot.time, slot.calendarId)
                    }, 200)
                    
                   }
                }
                return results
            }
            return []
        }
        , [props.calendarDays, selectedDate, props.preSelectedSlot])
    const handleTimeClick = (date: Date, time: string, calendarId: string) => {
        props.onDateTimeSelected( moment(date).format(DEFAULT_DATE_FORMAT), time, calendarId)
    }
    // useEffect(() => {
    //     if (selectedDate && timeSlotsRef && !alreadyScrolled && props.calendarDays) {
    //         setTimeout(() => {
    //         //@ts-ignore
    //         timeSlotsRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
    //         setAlreadyScrolled(true)
    //         }, 450)
    //     }
    // }, [selectedDate,  state.orderType, timeSlotsRef, props.calendarDays ])
    return (
        <React.Fragment>
            <div className={"text-center text-grey mx-2"}>

                <Calendar

                    numberOfMonths={1}
                    clearButtonClassName="hidden"
                    inline
                    locale={state.language.toLocaleLowerCase()}
                    className={classNames({
                        "xl:hidden": !props.forceSingle,
                    })}
                    value={selectedDate} viewDate={viewDate}
                    onChange={(e: any) => setSelectedDate(e.value)}
                    onViewDateChange={(e: any) => {setViewDate(e.value); props.onMonthUpdated(e.value, true);}}
                    minDate={minDate} maxDate={maxDate}
                    disabledDates={disabledDays}
                />
                <Calendar
                    numberOfMonths={2}
                    clearButtonClassName="hidden"
                    inline
                    locale={state.language.toLocaleLowerCase()}
                    className={classNames({
                        "hidden":true,
                        "xl:block ": !props.forceSingle,
                    })}
                    value={selectedDate} viewDate={viewDate}
                    onChange={(e: any) => setSelectedDate(e.value)}
                    onViewDateChange={(e: any) => {setViewDate(e.value); props.onMonthUpdated(e.value, false);}}
                    minDate={minDate} maxDate={maxDate}
                    disabledDates={disabledDays}
                />
            </div>
            <div className={"mt-4 px-2 py-2 "} >
                <div className=" mx-auto grid grid-cols-4 gap-2 max-w-410">
                    {availableTimesInSelectedDay().map((timeArray, timeArrayIndex) =>
                        <React.Fragment key={timeArrayIndex} >
                            {timeArray.map((time, timeIndex) =>
                                <button key={timeIndex} disabled={!time.available} ref={timeArrayIndex ===1 && timeIndex=== 0 ? timeSlotsRef : undefined}
                                        className={classNames({
                                            "py-2 px-2 mx-1 sm:px-6 md:px-5 time-button border-2 border-solid rounded-md": true,
                                            "bg-grey-50 text-grey-darker disabled": !time.available,
                                            "bg-secondary-dimmed border-secondary text-dark active": props.selectedTimeSlot?.type === 'timeslot' && props.selectedTimeSlot?.time === time.time
                                                && isSameDayInStringAndDate(moment(props.selectedTimeSlot?.day ?? '', 'DD-MM-YYYY'), moment(selectedDate))
                                        })}
                                        onClick={() => time.available && handleTimeClick(selectedDate, time.time, time.calendarId)}>{time.time}</button>
                            )}
                        </React.Fragment>
                    )}

                </div>

                <div className={"block lg:hidden mb-20"}/>

            </div>
        </React.Fragment>
    )
}
export default DateTimeSelector