import React from 'react';
import './Footer.scss';
import Dialog from './Dialog';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const {t} = useTranslation()
    return (
        <footer>
            <ul>
                <li><Dialog buttonText={t('Regulamin rezerwacji')} dialogUrl='https://storeapi.bookit.one/api/v1/shop/tenant/terms-of-use'/></li>
                <li><Dialog buttonText={t('Polityka prywatności')} dialogUrl='https://storeapi.bookit.one/api/v1/shop/tenant/privacy-policy'/></li>
                <li><div className={"dialog-wrapper"}>
                    <a href={"mailto:kontakt@bookit.one"} target="_blank">{t('Pomoc')}</a>
                </div></li>
            </ul>
            <div className={"copyright"}>&copy; {currentYear} Bookit.One</div>
        </footer>
    );
};

export default Footer;
