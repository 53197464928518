import {baseURL, client, tennatId} from './RealClient.service'
import {CalendarDay} from "../models/CalendarDay.model";
import {Cart} from "../models/Cart.model";
import {ProductAvailaiblity, SellableProduct} from "../models/Product.model";
import {OrderCheckout, OrderReponse, OrderSummary} from "../models/Order.model";
import {TenantInfo} from "../models/Details.model";
import {Tag} from "../models/Tags.model";


export const getItems = (timeout:number|undefined = 25000): Promise<SellableProduct[]> => {
        return new Promise<SellableProduct[]>((resolve, reject) =>
         client.get<SellableProduct[]>('product', { timeout: timeout})
             .then(res => resolve(res.data.filter(x => x.type !== 'VARIANT')))
             .catch(e => reject(e))
)}
export const getRangeItems = (): Promise<SellableProduct[]> => {
    return new Promise<SellableProduct[]>((resolve, reject) =>
     client.get<SellableProduct[]>('product')
         .then(res => resolve(res.data.filter(x => x.type === 'CONFIGURABLE_SHOOTING_RANGE')))
         .catch(e => reject(e))
    )
}

export const getCalendar = (frameLength: number, startDate: string, endDate: string, cartId?: string, productSku?: string, productQuantity?: number, frameStep?: number): Promise<CalendarDay[]> => {
    return new Promise<CalendarDay[]>((resolve, reject) =>
        client.get<CalendarDay[]>('calendar/availableSlots', {
            params: {
                frameLength,
                startDate,
                endDate,
                cartId,
                productSku,
                productQuantity,
                frameStep
            },
            timeout: 10000
        })
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    )
}


export const getCalendarByCalendarsId = (frameLength: number, startDate: string, endDate: string, calendars:string[]): Promise<CalendarDay[]> => {
    return new Promise<CalendarDay[]>((resolve, reject) =>
        client.get<CalendarDay[]>('calendar/availableSlots', {
            params: {
                frameLength,
                startDate,
                endDate
                },
            data: calendars,
            timeout: 5000
        })
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    )
}

export const createNewCart = (cart: Cart): Promise<Cart> => {
    return new Promise<Cart>((resolve, reject) =>
    client.post<Cart>('cart', cart)
        .then(res => resolve(res.data))
        .catch(error => reject(error.data)))
}

export const retriveCart = (cartId: string): Promise<Cart> => {
    return new Promise<Cart>((resolve, reject) => {
        client.get<Cart>(`cart/${cartId}`).then(res => resolve(res.data)).catch(error => reject(error.data))
    })
}

export const updateCart = (cart: Cart):Promise<Cart> => {
    return new Promise<Cart>((resolve, reject) => {
        if (cart.id) {
            client.put<Cart>(`cart/${cart.id}`, cart)
                .then(res => resolve(res.data))
                .catch(error => reject(error.response.data))
        } else {
            client.post<Cart>(`cart`, cart)
                .then(res => resolve(res.data))
                .catch(error => reject(error.response.data))
        }
    })
}
export function getImageUrl(sku: string, type: string): string {
    return baseURL + `product/${sku}/image/${type}?tenantId=${tennatId}`
}
export const createOrder = (order: OrderCheckout): Promise<OrderReponse> => {
    return new Promise<OrderReponse>((resolve, reject) =>
        client.post<OrderReponse>('order/checkout', order)
            .then(res => resolve(res.data))
            .catch(error => reject(error.response.data)))
}

export const logError = (error: string, request: string, stacktrace: string): Promise<void> => {
    return new Promise<void>((resolve, reject) =>
        client.post<void>('/debug/error-logger', {
            message: error,
            request: request,
            stacktrace: stacktrace
        })
            .then(res => resolve())
            .catch(error => reject(error.response.data)))
}

export const getDetails = (): Promise<TenantInfo> => {
    return new Promise<TenantInfo>((resolve ,reject) =>
        client.get<TenantInfo>('tenant/info')
            .then(res => resolve(res.data))
            .catch(error => {
                client.get<TenantInfo>('tenant/info').then(res => resolve(res.data))
                .catch(error => {reject(error ? error.response.data : null)})}))
            }

export const getTags = (): Promise<Tag[]> => {
    return Promise.resolve(
        [
            {label: "Strzelanie", key: 'packages'},
            {label: "Pakiety kwotowe", key: 'vouchers'},
            {label: "Szkolenia", key: 'trainings'},
            {label: "Zawody", key: 'competitions'},
            {label: "Klub", key: 'club'},
            {label: "Wynajem stanowisk", key: 'ranges'},
            {label: "Rzucanie siekierami", key:'axes'}
        ]
        )
}

export const getProductAvailability = (sku: string, date: string): Promise<Array<ProductAvailaiblity>> => {
    return new Promise<Array<ProductAvailaiblity>>((resolve, reject) => 
        client.get<Array<ProductAvailaiblity>>(`product/${sku}/availability`, {
            params: {
                date,
                frameLength: 60,
                frameStep: 60
                }
            }
        ).then(res => resolve(res.data))
    )
}
export const getOrderDetals = (orderId: string): Promise<OrderSummary> => {
    return new Promise<OrderSummary>((resolve,reject) => client.get<OrderSummary>(`order/${orderId}/summary`).then(res => resolve(res.data)))
}