import React, {useContext, useEffect} from "react";
import './PackageUpsellDialog.scss'
import {BundleAttributes, SellableProduct, ExtensionAttributes, BundleProduct} from "../../models/Product.model";
import PackageUpsellDialogItem from "./PackageUpsellDialogItem";
import {CartContext, Types} from "../../contexts/reducers";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {getPackageLabel} from "../../utils/UnitUtils";
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider";
import { usePostHog } from "posthog-js/react";

type PackageUpsellDialogProps = {
    package: SellableProduct & BundleAttributes,
    extended: SellableProduct & ExtensionAttributes,
    premium: SellableProduct & ExtensionAttributes,
    isUpsellDialogOpen: boolean,
    onIsUpsellDialogOpenChange: (isUpsellDialogOpen: boolean) => void,
}
export const PackageUpsellDialog = (props: PackageUpsellDialogProps) => {
    const { state, dispatch } = useContext(CartContext);
    const {currency} = useCurrency()
    const {t} = useTranslation()
    const posthog = usePostHog()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.isUpsellDialogOpen])
    const getExtendedPackagePrice = (): number => {
        return props.extended.price
    }
    const getPremiumPackagePrice = (): number => {
        return props.premium.price
    }
    const selectProduct = (product: SellableProduct): void => {
        if (product.sku === props.premium.sku) {
            posthog.capture('merch upselled premium', {item_id: product.sku, item_name: product.name, price: product.price + props.package.price})
        } else if (product.sku === props.extended.sku) {
            posthog.capture('merch upselled extended', {item_id: product.sku, item_name: product.name, price: product.price + props.package.price})
        } else {
            posthog.capture('merch not_upselled', {item_id: product.sku, item_name: product.name, price: product.price})
        }
        dispatch({
            type: Types.Add,
            payload: [{
                sku: product.sku,
                type: product.type,
                quantity: 1,
                name: product.name,
                price: product.price + props.package.price,
                taxRate: product.taxRate,
                duration: product.duration ?? 0
            }]
        })
        props.onIsUpsellDialogOpenChange(false)
    }

    return (
        <React.Fragment>
            {props.isUpsellDialogOpen &&
            <div className="p-dialog-mask p-component-overlay p-dialog-visible p-dialog-center"
                 style={{"zIndex": 1000, "backgroundColor":"rgba(255, 255, 255, 0.95)"}}>
                <div className="hidden lg:block p-component relative w-full overflow-y-auto absolute max-h-90vh" style={{maxWidth:'920px'}}>
                    <div className={"text-center mt-6 font-bold text-3xl"}>{t('Powiększ wybrany pakiet')}</div>
                    <div className={"flex justify-center"}>
                        <div className={"border border-t-8 min-h-vh50 border-grey rounded mt-4 ml-2 pt-4 bg-white align lg:w-1/3 relative"}>
                            <div className={"px-2 h-full flex flex-col  overflow-auto mb-32"}>
                                <div>
                                    <div className={"p-2 pl-0 pt-4 text-center"}>
                                        <span className={"font-bold text-3xl"}>{t('Standard')}</span>
                                    </div>
                                </div>
                                {/*<span className={"font-bold pt-4 text-center"}>Zestaw zawiera:</span>*/}

                                {/*{props.package.children.map((weapon => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>))}*/}

                            </div>
                            <div className={"mt-auto absolute bottom-0 left-0 w-full bg-white px-2"}>
                                <div className={"items-right mt-2 mx-auto"}>
                                    <div className={"text-center"}>
                                        <span
                                            className={"font-bold "}>{t('Podstawowa zawartość')}</span>
                                    </div>
                                    <button
                                        onClick={() => selectProduct(props.package)}
                                        className="shadow-lg w-full align-middle rounded items-center bg-white px-3 py-2 mb-4 border-2 border-secondary justify-center font-bold mx-auto hover:bg-secondary-dimmed">
                                            <span
                                                className="font-medium text-lg">{getFormattedPrice(props.package.price, true, currency)} </span><br/><span
                                        className={"font-normal text-sm"}>{t('Nie powiększaj zestawu')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"border border-t-8 min-h-vh50 border-secondary rounded mt-4 ml-2 pt-4 bg-white align  lg:w-1/3  relative"}>
                            <div className={"mx-2 flex flex-col h-full overflow-auto pb-16"}>
                                <div>
                                    <div className={"p-2 pl-0 pt-4 text-center"}>
                                        <span className={"text-secondary font-bold text-3xl"}>{t('Premium')}</span>
                                    </div>
                                </div>
                                <span className={"font-bold pt-4 text-center"}>{t('W zestawie dodatkowo')}:</span>

                                {props.premium.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>))}
                                <div className={"mb-16"} />
                                <div className={"mt-auto absolute bottom-0 left-0 w-full bg-white px-2"}>
                                    <div className={"items-right mt-2 mx-auto"}>
                                        <div className={"text-center"}>
                                            {//@ts-ignore
                                                getPackageLabel(props.premium, [], 1, state.language).map((label, index) => (
                                                <span className={"font-bold "} key={index}>  {label} {t('więcej')}! <br/></span>
                                                ))}
                                        </div>
                                        <button
                                            onClick={() => selectProduct(props.premium)}
                                            className="shadow-lg w-full align-middle rounded items-center bg-secondary text-white px-3 py-2 mb-4 justify-center font-bold mx-auto hover:bg-secondary-lighter">
                                            <span className="font-bold text-lg">+ {getFormattedPrice(getPremiumPackagePrice(), true, currency)}</span><br/><span
                                            className={"text-sm font-bold "}>{t('Zestaw premium')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"border border-t-8 min-h-vh50 border-secondary rounded mt-4 ml-2 pt-4 bg-white align  lg:w-1/3  relative"}>
                            <div className={"mx-2 flex flex-col h-full overflow-auto pb-16"}>
                                <div>
                                    <div className={"p-2 pl-0 pt-4 text-center"}>
                                        <span className={"text-secondary font-bold text-3xl"}>{t('Powiększony')}</span>
                                    </div>
                                </div>
                                <span className={"font-bold pt-4 text-center"}>{t('W zestawie dodatkowo')}:</span>

                                {props.extended.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>))}
                                <div className={"mb-16"} />
                                <div className={"mt-auto absolute bottom-0 left-0 w-full bg-white px-2"}>
                                    <div className={"items-right mt-2 mx-auto"}>
                                        <div className={"text-center"}>
                                            {//@ts-ignore
                                                getPackageLabel(props.extended, [], 1, state.language).map((label, index) => (
                                                    <span className={"font-bold "} key={index}>  {label} {t('więcej')}! <br/></span>
                                                ))}
                                        </div>
                                        <button
                                            onClick={() => selectProduct(props.extended)}
                                            className="shadow-lg w-full align-middle rounded items-center text-white bg-secondary px-3 py-2 mb-4 justify-center font-bold mx-auto hover:bg-secondary-lighter ">
                                            <span className="font-bold text-lg">+ {getFormattedPrice(getExtendedPackagePrice(), true, currency)} </span><br/><span
                                            className={"text-sm font-bold "}>{t('Zestaw powiększony')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="block lg:hidden p-component relative h-full w-full bg-grey-150 overflow-y-auto absolute">
                    <div className="p-3 bg-primary text-white w-full justify-between z-30 text-lg">
                        {t('Powiększ wybrany pakiet')}
                    </div>
                    <div className={"border-t-4 border-grey bg-white"}>
                        <div className={"flex justify-between px-2 border-b border-grey-500"}>
                            <div>
                                <div className={"mt-4"}>
                                    <span
                                        className={"rounded-2xl p-2 px-4 font-bold bg-grey-500 border-2 border-grey-500 text-white"}>{t('Podstawowy')}</span>
                                </div>
                                <div className={"py-4 font-bold text-lg  px-4 w-full"}>
                                    <span className={"align-top"}>
                                    {getFormattedPrice(props.package.price, true, currency)}
                                    </span>
                                </div>
                            </div>
                            <div className={"items-right mt-4"}>

                                <button
                                    onClick={() => selectProduct(props.package)}
                                    className="block align-middle items-center bg-white px-3 py-2 mb-4 border-secondary border-2 rounded justify-center font-medium mx-auto hover:bg-secondary-dimmed">
                                    <span className="p-button-label p-c">{t('Nie powiększaj')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"border-t-4 border-secondary mt-4 bg-white"}>
                        <div className={"flex justify-between px-2 border-b border-grey-500"}>
                            <div>
                                <div className={"mt-4"}>
                                    <span
                                        className={"rounded-2xl p-2 px-8 font-bold bg-secondary text-white border-2 border-secondary"}>{t('Premium')}</span>
                                </div>
                                <div className={"pt-4"}>
                                    <span>
                                         {//@ts-ignore
                                             getPackageLabel(props.premium, [], 1, state.language).map((label, index) => (
                                                 <span className={"text text-primary font-bold "} key={index}>  {label} {t('więcej')}! <br/></span>
                                             ))}
                                    </span>
                                </div>
                            </div>
                            <div className={"items-right"}>
                                <div className={"pt-2 pr-4 pb-2 font-bold text-lg text-right w-full"}>
                                    <span className={"align-top"}>
                                    + {getFormattedPrice(getPremiumPackagePrice(), true, currency)}
                                    </span>
                                </div>
                                <button
                                    onClick={() => selectProduct(props.premium)}
                                    className="block align-middle items-center bg-secondary text-white hover:bg-secondary-lighter px-3 py-2 mb-4 rounded justify-center font-bold mx-auto">
                                    <span
                                    className="p-button-label p-c">{t('Wybierz')}</span>
                                </button>
                            </div>
                        </div>
                        <div>
                        <div >
                        {props.premium.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>))}
                        </div>
                            {/*<div className={"bottom-cart-shadow"}>*/}
                            {/*    Pokaż więcej*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={"border-t-4 border-secondary mt-4 bg-white"}>
                        <div className={"flex justify-between px-2 border-b border-grey-500"}>
                            <div>
                                <div className={"mt-4"}>
                                    <span
                                        className={"rounded-2xl p-2 px-6 font-bold bg-secondary text-white border-2 border-secondary"}>{t('Rozszerzony')}</span>
                                </div>
                                <div className={"pt-4"}>
                                <span>
                                    {//@ts-ignore
                                        getPackageLabel(props.extended, [], 1, state.language).map((label, index) => (
                                            <span className={"text text-primary font-bold "} key={index}>  {label} {t('więcej')}! <br/></span>
                                        ))}
                                </span>
                                </div>
                            </div>
                            <div className={"items-right"}>
                                <div className={"pt-2 pr-4 pb-2 font-bold text-lg text-right w-full"}>
                                    <span className={"align-top"}>
                                        + {getFormattedPrice(getExtendedPackagePrice(), true, currency)}
                                    </span>
                                </div>
                                <button
                                    onClick={() => selectProduct(props.extended)}
                                    className="block align-middle items-center bg-secondary text-white px-3 py-2 mb-4 hover:bg-secondary-lighter rounded  justify-center font-bold mx-auto">
                                   
                                    <span className="p-button-label p-c">{t('Wybierz')}</span>
                                </button>
                            </div>
                        </div>
                        {props.extended.children.map(((weapon:BundleProduct) => <PackageUpsellDialogItem item={weapon} key={weapon.product.id}/>))}
                    </div>
                </div>
            </div>}
        </React.Fragment>
    )
}

export default PackageUpsellDialog;
