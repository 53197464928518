import React from "react";
import './AddToCartButton.scss'
import classNames from "classnames";
import {Tooltip} from "primereact/tooltip";
import {useTranslation} from "react-i18next";
import { BiCart } from "react-icons/bi";

export type AddToCartButtonProps = {
    onAddToCartClick: () => void,
    disabled: boolean,
    className?: string,
    containerClassName?: string,
    outOfStock?: boolean
}

export const AddToCartButton = (props: AddToCartButtonProps) => {
    const {t} = useTranslation()
    // @ts-ignore
    return (
        <div className={props.containerClassName}>
            <Tooltip position="top" disabled={!props.outOfStock} target={".add-to-cart-disabled"}>{t('Produkt wyprzedany')}</Tooltip>
            <button className={classNames({ "add-to-cart-disabled": props.disabled || props.outOfStock, "add-to-cart": true, "add-to-cart-hover":!(props.disabled || props.outOfStock)}) + " " + props.className} onClick={() => {if (!props.disabled && !props.outOfStock){ props.onAddToCartClick()}}}
                    disabled={props.disabled}>
                        <BiCart size={25} className="hidden md:block mr-2 sm:font-semibold"/>
                <span className="p-1 sm:p-2 sm:font-semibold">{t('Dodaj do koszyka')}</span>
            </button>
        </div>
    )
}
export default AddToCartButton