import React, { useState, useEffect, useContext } from "react"
import { RangeGroupProduct, RangeVariant, SellableProduct, Variant } from "../../models/Product.model"
import classNames from "classnames"
import { getImageUrl } from "../../utils/Api.service"
import { getFormattedPrice } from "../../utils/PriceUtils"
import LoadableImage from "../Image/LoadableImage"
import { Checkbox } from "primereact/checkbox"
import { CartContext, Types } from "../../contexts/reducers"
import { BiTable } from "react-icons/bi"
import { Button } from "primereact/button"
import ReactGA from 'react-ga4';
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider"
import { usePostHog } from "posthog-js/react"
import { useTenant } from "../../contexts/TenantProvider"
import ReactPixel from 'react-facebook-pixel';


type RangeOtySelectorProps = {
    variant?: Variant & RangeVariant,
    parent?: SellableProduct,
    onForward: (selectedVariant: Variant, selectedQty: number) => void,
    onShowAvailability: (range: SellableProduct & RangeGroupProduct, variantSku: string) => void,
    onChangeLegalRequirement: (newValue: boolean) => void,
    isLegalRequiremendChecked: boolean,
}
export const RangeQtySelector = (props: RangeOtySelectorProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const {isTenantRequiredShootingLeaderPermission} = useTenant()
    const posthog = usePostHog()
    const [selectedVariant, setSelectedVariant] = useState<Variant>()
    const [baseVariants, setBaseVariants] = useState<Array<Variant>>([])
    const [alternativeVariants, setAlternativeVariants] = useState<Array<Variant>>([])
    const [variantsToDisplay, setVariantsToDisplay] = useState<Array<Variant>>([])
    const [isRangeSelector, setIsRangeSelector] = useState<boolean>(false)
    const [price, setPrice] = useState<number>(99)
    const [selectedQty, setSelectedQty] = useState<number>(1)
    const [selectedQtyType, setSelectedQtyType] = useState<string>('normal');
    const [selectedQtyIndex, setSelectedQtyIndex] = useState<number>(0)
    const [image, setImage] = useState<string>('')
    const {state, dispatch} = useContext(CartContext);

    const [isShootingLeaderRequired, setIsShootingLeaderRequired] = useState(true);

    const [qtyOptions, setQtyOptions] = useState<Array<{qty: number, label: string, priceMultiplier: number, type: string}>>([])
    useEffect(() => {

        setIsRangeSelector(props.variant?.value === "Stanowisko")
        setPrice(props.variant?.product.price ?? 0)
        setImage(getImageUrl(props.parent?.sku ?? '', 'large'))
        setSelectedVariant(props.variant?.product.variants[0])
        //@ts-ignore
        setBaseVariants(props.variant.product.variants)
        //@ts-ignore
        setAlternativeVariants(props.parent?.variants.find(x => x.value === "Oś")?.product.variants ?? null)
        //@ts-ignore
        const variants = props.parent?.variants.reduce((arr,elem) => [...arr, ...elem.product.variants], [])
        //@ts-ignore
        const filtered = variants.filter((obj: any,index: number) => variants.findIndex(item=> item.value === obj.value) === index)
        //@ts-ignore
        setVariantsToDisplay(filtered)
    }, [])

    
    useEffect(() => {
            const variantDuration =selectedVariant?.product.duration ?? 0

            let quantityOptions = []
            let hasMaster = false
            //@ts-ignore
            let fullRangeOption = props.parent?.variants.find(x => x.value === "Oś")?.product.variants.find(y => y.value === selectedVariant?.value) ?? false
            //@ts-ignore
            let laneOption = props.parent?.variants.find(x => x.value === "Stanowisko")?.product.variants.find(y => y.value === selectedVariant?.value)
            //@ts-ignore
            if (laneOption) {

                const rangeProduct = fullRangeOption ? fullRangeOption.product.price : Number.MAX_VALUE
                const basePrice = laneOption?.product.price ?? 0
                for (let i = 0; i < (props.variant?.product?.limit ?? 0); i++) {
                    const qty = i + 1
                    const label = `${i + 1}`
                    const priceMultiplier = (i + 1)
                    const price  = (i + 1) * basePrice 
                    const type = 'base'
                    if (price <= rangeProduct ) {
                        quantityOptions.push({qty, label, priceMultiplier, type})
                    } else {
                        if (!hasMaster) {
                            hasMaster = true
                            quantityOptions.push({qty: 1, label: t("Cała oś"), priceMultiplier: 1, type: 'upsell'})
                        }
                    }
                }
                // dodaj stanowiska
            } else {
                quantityOptions.push({qty: 1, label: t("Cała oś"), priceMultiplier: 1, type: 'upsell'})
            }
          
            ReactGA._gtag("event", "view_item", {
                currency: currency,
                value: ((props.parent?.price ?? 0) / 100.00),
                "items": [{
                    "item_id": props.parent?.sku ?? '',
                    "item_name": props.parent?.name ?? '',
                    "currency": currency,
                    "price": (props.parent?.price ?? 0) / 100.00
                }]
            })

            posthog.capture('merch product_viewed', {

                "item_id": props.parent?.sku ?? '',
                "item_name": props.parent?.name ?? '',
                "currency": currency,
                "price": ((props.parent?.price ?? 0) / 100.00)
            })
          
            setQtyOptions(quantityOptions)
            if (quantityOptions.length  < selectedQty) {
                setSelectedQty(1)
                setPrice(selectedVariant?.product.price ?? 0)
            }
        
    }, [selectedVariant])
    const clearCart = () => {
        if (state.cart.items.length > 0) {
            // dispatch({type: Types.Delete, payload:state.cart.items[0]})
            dispatch({type: Types.RemoveTimeslot, payload:null})
        }
    }
    const showAvailbility = () => {
         //@ts-ignore
        return  props.parent?.variants.find(x => x.value === "Stanowisko") && !props.parent?.tags.find(x => x.key === 'throw')
    }
    const openAvailability = () => {
        if (props.parent) {
        //@ts-ignore
        props.onShowAvailability(props.parent, props.variant.product.sku)
        posthog.capture('merch range show_availability', {
            "item_id": props.parent?.sku ?? '',
            "item_name": props.parent?.name ?? '',
            "currency": currency,
            "price": ((props.parent?.price ?? 0) / 100.00)
        })
    }
    }
    return (
        <React.Fragment>
            <div className="lg:flex relative ">
                <div className={"w-full mt-4"}>
                    <div className={"bg-white pb-8"}>
                        
                        <div className={"box-border w-full lg:w-1/2 inline-block"}>
                            <div className={"hidden lg:block  left-0 top-0 "}>
                            <LoadableImage
                                classes={"h-full w-full md-4 object-contain object-left-top"}
                                thumbnailData={"data:" + props.parent?.thumbnailType + ";base64, " + props.parent?.thumbnail}
                                imageSrc={image}
                                previewAvailable={false}
                            />
                            </div>
                            <LoadableImage
                                classes={"lg:hidden w-full md-4 object-contain max-h-250"}
                                thumbnailData={"data:" + props.parent?.thumbnailType + ";base64, " + props.parent?.thumbnail}
                                imageSrc={image}
                                previewAvailable={true}
                            />
                        </div>
                        <div className={"w-full lg:w-1/2 inline-block align-top bg-white"}>
                            <div className={"px-4"}>
                                <div>
                                    <div className={"font-bold text-2xl inline-block"}>{props.parent?.name}</div>
                                    {showAvailbility() && 
                                        <Button className="availability-button availability-button-hover bg-white border-grey shadow rounded" onClick={() => {openAvailability()}}>
                                        <BiTable className="float-left mt-3"/> <span className={"text float-right"}>{t('Dostępność')}</span>
                                        </Button>}
                                    </div>
                                    <div className="text-xl font-bold pt-4  ">
                                        {props.variant?.product.variants[0].name}
                                    </div>
                                    <div className={"mt-4 flex flex md:inline-flex flex-wrap w-full pb-2"}>
                                        {variantsToDisplay.map((variant: Variant) => (
                                            <div
                                                onClick={() => {
                                                    let selectedItem = selectedVariant
                                                    selectedItem = variant
                                                    setSelectedVariant(selectedItem)
                                                    setSelectedQty(1)
                                                    setSelectedQtyIndex(0)
                                                    setPrice(selectedItem.product.price * 1)
                                                    clearCart()
                                                }}
                                                key={variant.product.id}
                                                className={classNames({
                                                    "cursor-pointer rounded  px-3 py-2 mr-4 last:mr-0 mb-4 hover:border-secondary border-2": true,
                                                    "border-secondary": selectedVariant?.value === variant.value
                                                })}
                                            >{variant.value}</div>
                                        ))}
                                    </div>
                                    {qtyOptions.length > 0 && <>
                                    <div className="pt-4 text-xl font-bold border-t">
                                        {props.variant?.value === "Oś" ? t("Liczba osi") : t("Liczba stanowisk")}
                                    </div>
                                    <div className={"mt-4 flex mb-4 flex md:inline-flex flex-wrap w-full"}>
                                        
                                         {qtyOptions.map((qtyOption,index) => (
                                            <div
                                                onClick={() => {
                                                    setSelectedQtyIndex(index)
                                                    if (qtyOption.type === 'upsell') {
                                                        //setVariantsToDisplay(alternativeVariants)
                                                        const newVariant = alternativeVariants.find( x=> x.value === selectedVariant?.value)
                                                        setSelectedVariant(newVariant)
                                                        setPrice(newVariant?.product.price ?? 0)
                                                        setSelectedQty(1)
                                                        setSelectedQtyType('upsell')

                                                    } else {
                                                        //setVariantsToDisplay(baseVariants)
                                                        const newVariant = baseVariants.find( x=> x.value === selectedVariant?.value) ?? baseVariants[0]
                                                        setSelectedVariant(newVariant)
                                                        setSelectedQty(qtyOption.qty)
                                                        setPrice(qtyOption.priceMultiplier * (newVariant?.product.price ?? 0))
                                                        setSelectedQtyType('standard')
                                                    }
                                                    clearCart()
                                                   
                                                }}
                                                key={index}
                                                className={classNames({
                                                    "cursor-pointer rounded text-center min-w-52px px-1 py-2 mr-4 last:mr-0 mb-4 hover:border-secondary border-2": true,
                                                    "border-secondary": index === selectedQtyIndex,
                                                    "px-4": qtyOption.label === "Cała oś",
                                                })}
                                            >{ qtyOption.label}</div>
                                        ))}
                                    </div>
                                    </>}
                                    {isTenantRequiredShootingLeaderPermission() && ((!isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') ) && 
                                        <div className="mt-4">
                                            <div className="w-full mt-2 mb-4 flex">
                                                <Checkbox inputId="agreementLegal"
                                                        name="agreementLegal"
                                                        checked={props.isLegalRequiremendChecked}
                                                        className={classNames({'p-invalid': ( !isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.isLegalRequiremendChecked})}
                                                        onChange={(e) => {props.onChangeLegalRequirement(e.checked ?? false); posthog.capture('merch confirmed_instructor_license')}}/>
                                                <label htmlFor="agreementLegal" className="p-checkbox-label ml-4">
                                                   {t('Posiadam uprawnienia prowadzącego strzelanie.')}
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    <div className="flex">
                                      
                                        <div className=" w-1/2 text-2xl mt-4 font-bold">{getFormattedPrice(price, true, currency)}</div>
                                        <button 
                                            disabled={isTenantRequiredShootingLeaderPermission() && ( !isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') && !props.isLegalRequiremendChecked} 
                                            className={"w-1/2 add-to-cart add-to-cart-xsmall " + (isTenantRequiredShootingLeaderPermission() && ((!isRangeSelector || selectedQty > 1 || selectedQtyType === 'upsell') && !props.parent?.tags?.find(x => x.key === 'throw') && !props.isLegalRequiremendChecked) ? " add-to-cart-disabled" : " add-to-cart-hover")} 
                                            onClick={() => {if (selectedVariant) {props.onForward(selectedVariant, selectedQty);         
                                                posthog.capture('merch add_to_cart', {
                                                    currency: currency,
                                                    value: (price / 100.00),
                                                    "items": [{
                                                        "item_id": props.parent?.sku ?? '',
                                                        "item_name": props.parent?.name ?? '',
                                                        "currency": currency,
                                                        "price": (price / 100.00),
                                                        "quantity": selectedQty
                                                    }]
                                                })
                                        }}}
                                        >
                                            <span className={"text font-semibold"}>{t('Dodaj do koszyka')}</span>
                                        </button>
                                    </div>
                                    <div className={"my-4 text"}>
                                    <span dangerouslySetInnerHTML={{ __html: props.variant?.product.description.replace(/(?:\r\n|\r|\n)/g, '<br>') ?? '' }} />
                                 
                                </div>
                            </div>
                            

                                                
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>)
}
export default RangeQtySelector