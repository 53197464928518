import React, {useContext, useEffect, useState} from 'react';
import './Range.scss';
import {RangeGroupProduct, SellableProduct} from "../../models/Product.model";
import {getImageUrl} from "../../utils/Api.service";
import LoadableImage from "../Image/LoadableImage";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {getPackageLabel} from "../../utils/UnitUtils";
import {useTranslation} from "react-i18next";
import { CartContext } from '../../contexts/reducers';
import { useCurrency } from '../../contexts/CurrencyProvider';


type RangeProps = {
    package: SellableProduct,
    onSetSelectedProduct: (product: SellableProduct & RangeGroupProduct) => void
}

export const Range = (props: RangeProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const {state} = useContext(CartContext)
    const [image, setImage] = useState<string>('')
    const [size, setSize] = useState<string>('large')
    const resize = () => {
        setSize('large')
        const newImage = getImageUrl(props.package.sku, size)
        if (newImage !== image) {
            setImage(newImage)
        }
    }

    const selectRange = () => {
        ///@ts-ignore
        props.onSetSelectedProduct(props.package)
    }

    useEffect(() => {
        resize()
    }, [])
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <React.Fragment>
            <div id={`prod_${props.package.id}`} className={"product-card mx-0 pb-4 lg:mb-4 mb-4 border-b border-grey-300 "}>
                <div className="md:hidden w-full p-0 min-h-100px">
                    <div className="product-desc-top row p-6">
                        <div className="w-full px-0" 
                        >
                        
                            <LoadableImage
                                classes={"product-image w-auto max-h-150 lg:max-h-200 my-auto mx-auto justify-center image-mobile-blurred"}
                                thumbnailData={"data:" + props.package.thumbnailType + ";base64, " + props.package.thumbnail}
                                imageSrc={image}
                                width={380}
                                previewAvailable={true}
                                height={200}
                            />
                        </div>
                        <div className={"mt-2"}>
                        <div className="text-lg  w-full sm:w-4/6  cursor-default font-semibold leading-4" style={{height: '2rem'}}>
                            {props.package.name}
                        </div>
                        <div className="flex">
                        <div className="text-left pt-2 text-black flex">
                            <span className="cursor-default">
                                {t("Od")}: <span className="font-semibold">{getFormattedPrice(props.package.price, false, currency)}  {t('/h')}</span></span>
                        </div>        
                        <div className={"add-to-cart-mobile"}>
                            <button className=" add-to-cart add-to-cart-hover" onClick={() => {selectRange()}}>
                                <span className={"px-4 p-1 sm:font-semibold text-md"}>{t('Wybierz oś')}</span>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="hidden md:flex w-full pl-8 p-4">
                    <div className="w-3/4 border-r-2 border-solid">
                        <div className="package-name text-xl font-bold">
                            {props.package.name}
                        </div>
                        <div className="flex w-full">
                            <div>
                                <LoadableImage
                                    classes={"max-w-full max-h-full block md-4 object-contain mt-4"}
                                    thumbnailData={"data:" + props.package.thumbnailType + ";base64, " + props.package.thumbnail}
                                    imageSrc={image}
                                    width={200}
                                    height={142}
                                    style={{"maxWidth": "200px"}}
                                    previewAvailable={true}
                                />
                            </div>
                            <div className="mt-2 pl-4 pt-2"  dangerouslySetInnerHTML={{__html: props.package.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
                        </div>
                    </div>
                    <div className="w-1/4">
                        <div className="text-4xl  text-right mt-8 mr-2/10">
                           <span className="font-light text-xl align-middle"> {t('Od')}: </span><span className="text-dark text-3xl font-bold">{getFormattedPrice(props.package.price, false, currency)}</span>
                        </div>
                        <div className="mx-2 w-full">
                            <div>
                                <button className="add-to-cart add-to-cart-hover" onClick={() => {selectRange()}}>
                                    <span className={"text"}>{t('Wybierz')}</span>
                                </button>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Range;
