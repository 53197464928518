import React, {useContext, useEffect, useState} from 'react';
import PackageItem from "./PackageItem"
import './Package.scss';
import PackageAddition from "./PackageAddition";
import PackageInfoDialog from "./PackageInfoDialog";
import PackageUpsellDialog from "./PackageUpsellDialog";
import {BundleAttributes, BundleProduct, ConfigurableAttributes, ProductOption, SellableProduct, SimpleProductAttributes} from "../../models/Product.model";
import {CartItem, CartItemOption} from "../../models/Cart.model";
import {getImageUrl} from "../../utils/Api.service";
import LoadableImage from "../Image/LoadableImage";
import {AddToCartButton} from "./Buttons/AddToCartButton";
import MoreDetailsButton from "./Buttons/MoreDetailsButton";
import {CartContext, Types} from "../../contexts/reducers";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {getCartItemLabel, getPackageLabel} from "../../utils/UnitUtils";
import CustomProductDialog from "../CustomProducts/CustomProductDialog";
import {TimeSlot} from "../../models/TimeSlot.model";
import moment from "moment";
import {useTranslation} from "react-i18next";
import { useCurrency } from '../../contexts/CurrencyProvider';


type PackageProps = {
    package: SellableProduct & BundleAttributes | SellableProduct & ConfigurableAttributes | SellableProduct & SimpleProductAttributes,
    additions: (SellableProduct & SimpleProductAttributes)[],
    extensions: SellableProduct[],
    setDismissButtonVisible: (visible: boolean) => void,
    forceOpen: boolean,
    unsetForceOpen: () => void
}

export const Package = (props: PackageProps) => {
    const { state, dispatch } = useContext(CartContext);
    const {currency} = useCurrency()
    const {t} = useTranslation()
    const extendedSku = props.package.type == 'BUNDLE' ? props.package.extensions?.extended?.sku ?? '' : ''
    const extended = props.extensions.find(x => x.sku === extendedSku)
    const premiumSku = props.package.type == 'BUNDLE' ? props.package.extensions?.premium?.sku ?? '' : ''
    const premium = props.extensions.find(x => x.sku === premiumSku)
    const [image, setImage] = useState<string>('')
    const [size, setSize] = useState<string>('large')
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    const [isUpsellDialogOpened, setIsUpsellDialogOpened] = useState<boolean>(false)
    const [selectedAdditions, setSelectedAdditions] = useState<CartItem[]>([])
    const [additionsprice, setAdditionsprice] = useState<number>(0)
    const isPackageCustom = props.package.type == "BUNDLE" && props.package.children.length == 0 && !props.package.customAttributes?.find(x => x.name === 'money-voucher' && x.value === 'true')
    const resize = () => {
        setSize('large')
        const newImage = getImageUrl(props.package.sku, size)
        if (newImage !== image) {
            setImage(newImage)
        }
    }
    useEffect(() => {
        resize()
        if (props.forceOpen) {
            setIsDialogOpened(true)
        }
    });

    const handleAddToCartButtonClick = (selectedItems: SellableProduct[], timeslot?: TimeSlot, cartItemOptions?: CartItemOption[]) => {
        // @ts-ignore
        let ts: { date: string; time: string; calendarId: string; } | undefined = undefined
        if (timeslot?.type === 'timeslot') {
            ts = {
                date: moment(timeslot.day, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                time: timeslot.time,
                calendarId: timeslot.calendarId ?? ''
            }
        }
        if (selectedItems.length > 0) {
            // @ts-ignore
            dispatch({
                type: Types.Add,
                payload: selectedItems.map(selectedItem => ({
                    sku: selectedItem.sku,
                    quantity: 1,
                    type: selectedItem.type,
                    name: selectedItem.name + "test",
                    quantityMultiplier: 0,
                    price: selectedItem.price,
                    taxRate: selectedItem.taxRate,
                    duration:selectedItem.duration ?? 0,
                    upsell: [],
                    timeslot: ts,
                    options: cartItemOptions
                }))
            })
        } else if (selectedAdditions.length > 0 || props.package.type !== 'BUNDLE' || (extended?.type !== 'EXTENSION') || (premium?.type !== 'EXTENSION')) {
            dispatch({
                type: Types.Add,
                payload: [{
                    sku: props.package.sku,
                    type: props.package.type,
                    quantity: 1,
                    name: props.package.name,
                    quantityMultiplier: 0,
                    price: props.package.price,
                    taxRate: props.package.taxRate,
                    duration: props.package.duration ?? 0,
                    upsell: JSON.parse(JSON.stringify(selectedAdditions))
                }]
            })
        } else {
            setIsUpsellDialogOpened(true)
        }
    }
    useEffect(() => {
        props.setDismissButtonVisible(!isUpsellDialogOpened)
    }, [isUpsellDialogOpened])

    const packageHasAnyProduct = () => {
        return props.package.type !== "BUNDLE" || props.package.children.length + selectedAdditions.length > 0 || props.package.customAttributes?.find(x => x.name === 'money-voucher' && x.value === 'true')
    }
    const hasVariants = () => {
        return props.package.type === "CONFIGURABLE" && (props.package.variants.length > 0 || props.package.calendars.length > 0)
    }
    const hasMandatoryOptions = () => {
        return (props.package.type === "CONFIGURABLE" && props.package.options.filter((x: ProductOption ) => x.required).length > 0)
    }
    const deleteAddition = (additionSku: string) => {
        setSelectedAdditions([...selectedAdditions.filter(x => x.sku !== additionSku)])
    }
    useEffect(() => {
        setAdditionsprice(selectedAdditions.reduce((a, b) => {
            return a + b.price * (b.quantity ?? 1)
        }, 0))
    }, [selectedAdditions])
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <React.Fragment>
            <div id={`prod_${props.package.id}`}
                 className={"product-card mx-0 pb-4 lg:mb-4 mb-4 ms:mb-0   border-b  border-grey-300"}>
                {props.package.type === 'BUNDLE' && (extended?.type == 'EXTENSION') && (premium?.type == 'EXTENSION') &&
                <PackageUpsellDialog
                    package={props.package}
                    isUpsellDialogOpen={isUpsellDialogOpened}
                    onIsUpsellDialogOpenChange={setIsUpsellDialogOpened} 
                    //@ts-ignore
                    extended={extended}
                    //@ts-ignore
                    premium={premium}/>
                }
                <div className="md:hidden w-full p-0 min-h-100px">
                    <div className="product-desc-top row relative m-6" >
                        <div className="w-full px-0 cursor-pointer" onClick={
                            (e) => {
                                setIsDialogOpened(true)
                            }}
                        >
                            { //@ts-ignore
                                    getPackageLabel(props.package, [], 1, state.language).map((label, index) => (
                                <div className="text-secondary text-sm mr-2  font-semibold" key={index}>{label}</div>
                                    ))
                                }
                                { props.package.outOfStock &&
                                <div className="text-red text-sm  mt-1 mr-2 ">{t('WYPRZEDANY')}</div>
                                }
                       
                             <div className="mt-1 mx-auto  pb-2" onClick={(e) => setIsDialogOpened(true)}>
                        
                        <div className="text-lg w-full cursor-default font-bold leading-4 cursor-pointer">{props.package.name}<br/></div>
                        <div className={"font-semibold mt-2"} onClick={(e) => setIsDialogOpened(true)}>
                            
                            {
                                props.package.type == "BUNDLE" && props.package.children.map(((weapon:BundleProduct) =>
                                        <div className={" text-grey-400 rounded-lg  mr-1 flex "}
                                             key={weapon.product.id}>
                                            <div className={"text-xs cursor-default cursor-pointer"}>{weapon.product.name}</div>
                                            { //@ts-ignore
                                                getPackageLabel(weapon.product, [],  weapon.quantity, state.language).map((label, index) => (
                                                    <div className="pl-1 text-xs text-grey-400 cursor-default" key={index}>{label}</div>
                                                ))
                                            }
                                            { props.package.outOfStock &&
                                            <span className="bg-red text-white p-1 text-sm float-left mt-2 mr-2 rounded">{t('WYPRZEDANY')}</span>
                                            }
                                        </div>
                                ))
                            }
                            {
                                selectedAdditions.map((addition =>
                                        <div className={"rounded-lg inline-block mr-1 mb-1 flex"}
                                             key={addition.sku}>
                                            <span className={"text-xs cursor-default"}>{addition.name}</span>
                                            { //@ts-ignore
                                                getCartItemLabel(addition, state.language).map((label, index) => (
                                                    <span className="pl-1 text-xs  cursor-default" key={index}>{label}</span>
                                                ))
                                            }
                                        </div>
                                ))
                            }
                        </div>
                       
                            <LoadableImage
                                classes={"mt-4 product-image w-auto max-h-150 lg:max-h-200 my-auto mx-auto justify-center "}
                                thumbnailData={"data:" + props.package.thumbnailType + ";base64, " + props.package.thumbnail}
                                imageSrc={image}
                                width={380}
                                height={200}
                                previewAvailable={false}
                            />
                            
                              </div>
                        
                           
                        </div>
                        
                        <div className={"flex mt-3"}>
                        <div
                                className="text-left pt-1 text-black flex ">
                                {
                                    
                                    <><span className="cursor-default font-semibold text-xl cursor-pointer"> {!packageHasAnyProduct() && t("Od") + ": "} {isPackageCustom ? (getFormattedPrice(props.package.price + additionsprice, false, currency)) : getFormattedPrice(props.package.price, false, currency)}</span></>
                                }
                                {(additionsprice > 0) && !isPackageCustom &&
                                <span className="pl-2 text-secondary cursor-default text-xl"> + {getFormattedPrice(additionsprice, false, currency)}</span>
                                }
                            </div>
                        {packageHasAnyProduct() && !hasVariants() && !hasMandatoryOptions() 
                        && <AddToCartButton containerClassName={"add-to-cart-mobile"} className="mr-1" outOfStock={props.package.outOfStock} onAddToCartClick={() => handleAddToCartButtonClick([])} disabled={false}/>}
                        <MoreDetailsButton containerClassName={"hidden md:block more-details-mobile " + ( packageHasAnyProduct() && !hasVariants() && !hasMandatoryOptions()?  "": "more-details-mobile-auto")} onButtonClick={() => setIsDialogOpened(true)} isPackageCustom={isPackageCustom}/>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex w-full pl-4 p-4 pb-2">
                    <div className="w-3/4 border-r-2 border-solid">
                       
                            { 
                                //@ts-ignore
                                !props.package.outOfStock && getPackageLabel(props.package, selectedAdditions, 1, state.language).map((label, index) => (
                                    <span className="rounded font-secondary font-bold text-secondary text-sm mt-2 bg-white mb-2" key={index}>{label}</span>
                                ))
                            }
                            {props.package.outOfStock &&
                            <span className="bg-red font-bold text-white p-1 text-sm mt-2 mr-2 rounded mb-2">{t('WYPRZEDANY')}</span>
                            }
                             <div className="package-name text-xl font-bold">
                                <div onClick={(e) => setIsDialogOpened(true)}>
                                {props.package.name}
                                </div>
                            
                           

                        </div>
                        <div className="flex w-full">
                            <div className="hidden xl:block">
                                <LoadableImage
                                    classes={"block max-w-full max-h-200 md-4 object-contain mt-2"}
                                    thumbnailData={"data:" + props.package.thumbnailType + ";base64, " + props.package.thumbnail}
                                    imageSrc={image}
                                    width={280}
                                    height={180}
                                    style={{"maxWidth": "280px"}}
                                previewAvailable={true}
                                />
                                </div>
                                <div className="xl:hidden block">
                                 <LoadableImage
                                    classes={"  max-w-full max-h-200 block md-4 object-contain mt-2"}
                                    thumbnailData={"data:" + props.package.thumbnailType + ";base64, " + props.package.thumbnail}
                                    imageSrc={image}
                                    height={180}
                                    width={200}
                                    style={{"maxWidth": "200px"}}
                                previewAvailable={true}
                                />
                            </div>
                            <div className="hidden md:inline-block w-full">
                                <div className="px-4   flex flex-wrap items-strech">
                                    {props.package.type === 'CONFIGURABLE' && <div style={{height: 190, overflow: 'hidden'}}dangerouslySetInnerHTML={{__html:props.package.description.replaceAll("<p>","").replaceAll("</p>","")}}/>}
                                    {props.package.type == "BUNDLE" && props.package.children.map(((weapon: BundleProduct) =>
                                        <PackageItem item={weapon} key={weapon.product.id}/>))}
                                    {selectedAdditions.map((addition => <PackageAddition addition={addition}
                                                                                         key={addition.sku}
                                                                                         onAdditionDelete={() => deleteAddition(addition.sku)}/>))}
                                </div>
                            </div>
                            <div className={"md:hidden ml-2 mt-2"} style={{flexFlow: 'column'}} onClick={(e) => setIsDialogOpened(true)}>
                            {
                                props.package.type == "BUNDLE" && props.package.children.map(((weapon:BundleProduct) =>
                                        <div className={"h-fit px-1  rounded-lg inline-block mr-1 mb-1 flex"}
                                             key={weapon.product.id}>
                                            <div className={"text-xs "}>{weapon.product.name}1</div>
                                            { //@ts-ignore
                                                getPackageLabel(weapon.product, [],  weapon.quantity, state.language).map((label, index) => (
                                                    <div className="pl-1 text-xs font-semibold" key={index}>{label}</div>
                                                ))
                                            }
                                            { props.package.outOfStock &&
                                            <span className="bg-red text-white p-1 text-sm float-left mt-2 mr-2 rounded">{t('WYPRZEDANY')}</span>
                                            }
                                        </div>
                                ))
                            }
                            {
                                selectedAdditions.map((addition =>
                                        <div className={"border-secondary border px-1  rounded-lg inline-block mr-1 mb-1 flex"}
                                             key={addition.sku}>
                                            <span className={"text-xs"}>{addition.name}</span>
                                            { //@ts-ignore
                                                getCartItemLabel(addition, state.language).map((label, index) => (
                                                    <span className="pl-1 text-xs font-semibold sm:text-base" key={index}>{label}</span>
                                                ))
                                            }
                                        </div>
                                ))
                            }
                        </div>
                        </div>
                    </div>
                    <div className="w-1/4">
                        <div className="pl-4 text-dark text-3xl font-bold text-center mt-8 w-full mb-2">
                            {
                                packageHasAnyProduct() &&
                                <span> {isPackageCustom ? (getFormattedPrice(props.package.price + additionsprice, false, currency)) : getFormattedPrice(props.package.price , false, currency)}</span>
                            }
                            {(additionsprice > 0) && !isPackageCustom &&
                            <span className="text-secondary"><br/> + {getFormattedPrice(additionsprice, false, currency)}</span>
                            }
                        </div>
                        <div className="mx-2 w-full">
                            {packageHasAnyProduct() && !hasVariants() && !hasMandatoryOptions() &&
                            <AddToCartButton outOfStock={props.package.outOfStock} onAddToCartClick={() => handleAddToCartButtonClick([])} disabled={false}/>
                            }
                            <MoreDetailsButton onButtonClick={() => setIsDialogOpened(true)} isPackageCustom={isPackageCustom}/>
                        </div>

                    </div>
                </div>
                {props.package.type === 'BUNDLE' &&
                <PackageInfoDialog isDialogOpened={isDialogOpened}
                                   onIsDialogOpenedChange={(value) => {setIsDialogOpened(value); props.unsetForceOpen();}} package={props.package}
                                   selectedAdditions={selectedAdditions}
                                   onSelectedAdditionsChange={setSelectedAdditions}
                                   onAddToCartButtonClick={handleAddToCartButtonClick}
                                   availableAdditions={props.additions}/>
                }
                {props.package.type === 'CONFIGURABLE' &&
                <CustomProductDialog isDialogOpened={isDialogOpened}
                                     onIsDialogOpenedChange={(value) => {setIsDialogOpened(value); props.unsetForceOpen();}}
                                     product={props.package}
                                     selectedAdditions={selectedAdditions}
                                     onSelectedAdditionsChange={setSelectedAdditions}
                                     onAddToCartButtonClick={handleAddToCartButtonClick}
                                  />
                }

            </div>
        </React.Fragment>
    )
}

export default Package;
