import React, { useContext, useEffect, useState } from 'react';
import './Dialog.scss';
import {tennatId} from "../../utils/RealClient.service";
import axios from 'axios';
import { Dialog as PrimeDialog } from 'primereact/dialog';
import {useTranslation} from "react-i18next";
import { CartContext } from '../../contexts/reducers';
import { LanguagesFullCodes } from '../../utils/Languages';

const Dialog = (props:{dialogUrl: string, buttonText: string}) => {
    const [dialogText, setDialogText] = useState("")
    const [showDialog, setShowDialog] = useState(false)
    const {t} = useTranslation()
    const {state} = useContext(CartContext);

    useEffect(() => {
        axios.get(`${props.dialogUrl}?X-Language=${LanguagesFullCodes[state.language]}&X-Tenant-ID=${tennatId}`).then(res => res.data).then(data => {
            setDialogText(data)
        })
    }, [])
    return (
        <div className={"dialog-wrapper"}>
            <a href="#" onClick={()=>setShowDialog(true)}>{props.buttonText}</a>
            <PrimeDialog visible={showDialog} onHide={() => setShowDialog(false)} style={{width: "100%"}}>
                <div className={"dialog-content"} dangerouslySetInnerHTML={{__html: dialogText}}>
                </div>
            </PrimeDialog>
        </div>
    );
};

export default Dialog;
