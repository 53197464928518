import {BundleProduct} from "../../models/Product.model";
import React, {useContext, useState} from "react";
import {getImageUrl} from "../../utils/Api.service";
import LoadableImage from "../Image/LoadableImage";
import {getPackageLabel} from "../../utils/UnitUtils";
import { CartContext } from "../../contexts/reducers";

type PackageUpsellDialogItemProps = {
    item: BundleProduct
}
export const PackageUpsellDialogItem = (props: PackageUpsellDialogItemProps) => {
    const [image, setImage] = useState<string>('')
    const newImage = getImageUrl(props.item.product.sku, 'large')
    const {state} = useContext(CartContext)
    if (newImage !== image) {
        setImage(newImage)
    }

    return (
        <div className={"pt-2 pl-6"} key={props.item.product.id}>
            <div className={"inline-block relative"}>
                <span className={"absolute top-0 -right-1 z-10 pi p-button-icon-left text-secondary"}>
                </span>
                <LoadableImage

                    previewAvailable={false}
                    classes={"product-image my-auto w-16 h-16 object-contain"}
                    thumbnailData={"data:" + props.item.product.thumbnailType + ";base64, " + props.item.product.thumbnail}
                    imageSrc={image}
                    height={256}
                />
            </div>
            <div className={"inline-block align-top ml-2"}>
                <div>
                    <span className={"font-bold"}>{props.item.product.name}</span>
                </div>
                <div className="-mt-1">
                    <span className={"text-grey-500 text-sm"}>
                        {getPackageLabel(props.item.product, [], props.item.quantity, state.language)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PackageUpsellDialogItem;
