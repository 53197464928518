enum SupportedLanguages {
    PL = 'PL',
    EN = 'EN',
}
enum SupportedLanguagesNames {
    PL = 'Polski',
    EN = 'English',
    }
enum SupportedLanguagesFlags {
    PL = 'PL',
    EN = 'GB',
}
enum LanguagesFullCodes {
    'PL' = "pl_PL",
    'EN' = "en_GB",
}
export { SupportedLanguages, SupportedLanguagesNames, SupportedLanguagesFlags, LanguagesFullCodes}
