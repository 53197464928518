import React, {useEffect, useState} from 'react';
import {Package as PackageView} from "./Package";
import {SellableProduct, SimpleProductAttributes} from "../../models/Product.model";
import ReactGA from "react-ga4";
import {useParams} from "react-router-dom";
import { useCurrency } from '../../contexts/CurrencyProvider';

type PackagesProps = {
    items:SellableProduct[]
    packages: SellableProduct[],
    setDismissButtonVisible: (visible: boolean) => void,
    resetSelectedTags: () => void,
    isDataLoaded: boolean,
    preSelectedSku: string,
    resetPreSelectedSku: () => void,
}
const Packages = (props: PackagesProps, {}) => {
    const [additions, setAdditions] = useState<(SellableProduct & SimpleProductAttributes)[]>([])
    const [selectedProduct, setSelectedProduct] = useState<string|null>(null)
    // @ts-ignore
    let {sku} = useParams()
    const {currency} = useCurrency()

    useEffect(() => {
            // @ts-ignore
        setAdditions(props.items.filter(x => x.type == "SIMPLE").sort((a,b) => {return a.position > b.position? 1 : -1}))
            ReactGA._gtag("event", "view_item_list", {
                "item_list_id": "products",
                "item_list_name": "Products",
                "items": props.packages.map(product => {
                    return {
                        "item_id": product.sku,
                        "item_name":product.name,
                        "currency":currency,
                        "price": (product.price / 100.00)
                    }
            })
            })
        if (props.preSelectedSku) {
            setSelectedProduct(props.preSelectedSku)
            setTimeout(() => {props.resetPreSelectedSku()}, 250)
        }

    }, [props.packages])

    return (
        <React.Fragment>
            {props.packages.filter(item => (item.type !== 'SIMPLE' && item.type !== 'EXTENSION')).length === 0 && !props.isDataLoaded &&
            <div className="mt-8 text-center">
                <span className="text-center block text-lg font-black">Brak produktów w wybranej kategorii.</span>
                <button onClick={props.resetSelectedTags} className="bg-secondary p-4 rounded text-white mt-4 pointer">Sprawdź całą ofertę</button>
            </div>}
            {
                props.packages.map(item => (item.type !== 'SIMPLE' && item.type !== 'EXTENSION') && <PackageView
                    key={item.sku}
                    //@ts-ignore
                    package={item}
                    additions={additions}
                    //@ts-ignore
                    extensions={item.type === 'BUNDLE' ? props.items.filter(x => (x.sku == (item.extensions?.extended?.sku ?? '')) || (x.sku == (item.extensions?.premium?.sku ?? ''))) : []}
                    setDismissButtonVisible={props.setDismissButtonVisible}
                    forceOpen={selectedProduct !== null && item.sku === selectedProduct}
                    unsetForceOpen={() => setSelectedProduct(null)}
                    />)
            }
            <div className={"mb-24 sm:mb-16"}/>
        </React.Fragment>
    );
};

export default Packages;
