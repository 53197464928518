import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from 'react-cookie'
import { useParams } from "react-router-dom";
interface Props {
    children: React.ReactNode;
}

const defaultCookiesConsent ={
        necessary: true,
        preferences: false,
        statistics: false,
        thirdParty: false,
        session: false,
    };
    interface CookieTypes {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        thirdParty: boolean;
        session: boolean;
    }
const CookiesContext = createContext<{
        necessary: boolean,
        preferences: boolean,
        statistics: boolean,
        thirdParty: boolean,
        session: boolean,
        acceptCookies: (cookies: CookieTypes)  => void,
        acceptAllCookies: ()  => void,
        hasCookie: boolean|undefined
}>({
    ...defaultCookiesConsent,
    acceptCookies:(cookies: CookieTypes) => {},
    acceptAllCookies: ()  => {},
    hasCookie: false
});

const ConsentCookiesProvider: React.FC<Props> = ({ children }) => {
  
    const [cookies, setCookie] = useCookies(['COOKIES_CONSENT']);
    const [hasCookie, setHasCookie] = useState<boolean|undefined>()

    const [cookiesInfo, setCookiesInfo] = useState<CookieTypes>({
        necessary: true,
        preferences: false,
        statistics: false,
        thirdParty: false,
        session: false,
    });
    const processUrlCookiesAcceptance = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const cookiesConfirmed = urlParams.get('cookiesConfirmed') ?? false
        if (cookiesConfirmed=== 'true') {
            acceptAllCookies()
            return true
        }
        return false
    }

    useEffect(() => {
        if (cookies.COOKIES_CONSENT) {
            setHasCookie(true)
            setCookiesInfo(cookies.COOKIES_CONSENT)
        } else {
            if (!processUrlCookiesAcceptance()) {
            setHasCookie(false)}
        }
    }, [cookies])

    

    const acceptCookies = (cookies: CookieTypes): void => {
        setCookie('COOKIES_CONSENT', cookies, {sameSite: 'none', secure: true, 'domain': '.bookit.one', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))})

    }
    const acceptAllCookies = (): void => {
        setCookie('COOKIES_CONSENT', {
            necessary: true,
            preferences: true,
            statistics: true,
            thirdParty: true,
            session: true,
        },
            {sameSite: 'none', secure: true, 'domain': '.bookit.one', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))})


    }

    return (<CookiesContext.Provider
    value={{
        ...cookiesInfo,
        acceptCookies: acceptCookies,
        acceptAllCookies:  acceptAllCookies,
        hasCookie: hasCookie
    }}>
    {children}
    </CookiesContext.Provider>)
}
const useCookiesConsent = () => useContext(CookiesContext)

export {CookiesContext, ConsentCookiesProvider, useCookiesConsent};