import React, {useContext, useEffect, useState} from 'react';
import {TimeSlot} from "../../models/TimeSlot.model";
import { FiChevronDown} from "react-icons/fi";
import {CartError} from "../../models/Cart.model";
import {DiscountResult} from "../../models/Discount.model";
import classNames from "classnames";
import {updateCart} from "../../utils/Api.service";
import {CartContext, Types} from "../../contexts/reducers";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {getCartItemLabel} from "../../utils/UnitUtils";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import { useCurrency } from '../../contexts/CurrencyProvider';

type ConfirmationProps = {
    selectedTimeslot?: TimeSlot,
    openVoucherInput: () => void,
    onUpdateStepIndex: (index: number) => void
    isDiscountDisabled?: boolean,
}
const ConfirmationSummary = (props: ConfirmationProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const { state, dispatch } = useContext(CartContext);

    const [voucherInputActive, setVoucherInputActive] = useState<boolean>(false)
    const [discountCode, setDiscountCode] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')

    const discount = state.cart.discounts.find(x => x)
    const itemVouchers = state.cart.discounts.filter(x => x.type === 'BUNDLE_VOUCHER') ?? []
    const removeDiscount = (discount: DiscountResult) => {
        const discounts = state.cart.discounts.filter(x => x.code !== discount.code)
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
        })
    }
    const processCouponCode = () => {
        if (discount) {
            removeDiscount(discount)
            return;
        }
        const discounts = state.cart.discounts
        discounts.unshift({code: discountCode})
        const newCart = {
            ...state.cart,
            discounts: discounts
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
            setSuccessMessage(t("Pomyślnie zaaplikowano kod"))
            setTimeout(() => {setSuccessMessage('')}, 6000)
        }).catch((errorResult: CartError) => {
            setErrorMessage(t(errorResult.errors.discounts.find(x => x.key === discountCode)?.message ?? ''))
            setTimeout(() => {setErrorMessage('')}, 6000)

            dispatch({type: Types.UpdateCart, payload: errorResult.fixedCart})
        })
    }
    useEffect(() => {
        if (state.cart.discounts.length > 0) {
            setDiscountCode(state.cart.discounts[0].code)
        }
    }, [])

    return (
        <React.Fragment>
            <div className="lg:hidden  mb-8 bg-white py-4 mx-0 sm:mx-6">
                <div className="text-xl font-bold">{t("Podsumowanie")}</div>
                {(props.selectedTimeslot?.type === 'voucher' || props.selectedTimeslot?.type === 'timeslot') &&
                <div className={"mt-1 rounded-lg "}>
                
                <div className={"mb-2 rounded-lg "}>
                    {props.selectedTimeslot?.type === 'voucher' &&
                        <React.Fragment>
                            <span className={"font-bold"}>
                                {t('Voucher dla')}: {props.selectedTimeslot.owner}<br/>
                            </span>
                            <div className="font-bold mt-4">
                            {t('Zawartość')}:
                        </div>
                        </React.Fragment>
                    }
                    {props.selectedTimeslot?.type === 'timeslot' &&
                    <React.Fragment>
                        <span className="float-left font-bold">
                            {t('Rezerwacja terminu')}:
                        </span>
                        <span className="float-right text-right">
                            {moment(props.selectedTimeslot?.day, 'DD-MM-yyyy').format('DD MMMM yyyy')}&nbsp;{props.selectedTimeslot?.time}
                        </span>
                        <div className={"clear-both"}/>
                        <span className="float-left ">
                            {t('Szacowana długość')}:
                        </span>
                        <span className="float-right">
                            {props.selectedTimeslot?.duration + " min"}
                        </span>
                        <div className={"clear-both mb-4"}/>
                        <div className="font-bold">
                            {t('Produkty')}:
                        </div>
                    </React.Fragment>
                    }
                </div>

                {itemVouchers.map((voucher, discountIndex) => {
                    return voucher.items?.map((kit, index) => {
                        return (<div className={""} key={`${discountIndex}${index}`}>
                            <div className="flex px-2 mt-2">
                                <span className="w-2/3 text-left text-dark"> {kit.quantity > 1 && kit.quantity + " " +t("szt.")} {kit.name}</span>
                                <span className="w-1/3 text-right text-dark">{t('Voucher')}</span>
                            </div>
                            {(kit.upsell?.length ?? 0) > 0 &&
                            <div className="pl-6 pr-2">
                                {kit.upsell?.map((el, index) => {
                                    return (
                                        <div className="w-full flex" key={index}>
                                            <span className="w-3/4 text-left text-dark">
                                                {el.name} (
                                                + {getCartItemLabel(el, state.language).map((result, index) => (result))})
                                            </span>
                                            <span className="w-1/4 text-right text-dark">
                                                {t('Voucher')}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                            }
                        </div>)
                    })
                })}
                {state.cart.items.filter(x => x.type !== 'CONFIGURABLE' && x.type !== 'VARIANT' && x.type !== 'MONEY_VOUCHER').map((kit, index) => {
                    return (<div key={index}>
                        <div className="flex mt-2">
                            <span className="w-2/3 text-left text-dark">
                                {kit.quantity > 1 && kit.quantity + " " +t("szt.")} {kit.name}
                            </span>
                            <span className="w-1/3 text-right text-dark">
                                {getFormattedPrice(kit.price, true, currency)}
                            </span>
                        </div>
                        {(kit.upsell?.length ?? 0) > 0 &&
                        <div className="pl-6 pr-2">
                            {kit.upsell?.map((el, index) => {
                                return (
                                    <div className="w-full flex" key={index}>
                                        <span className="w-3/4 text-left text-dark">
                                            + {el.name} ({getCartItemLabel(el, state.language).map((result, index) => (result))})
                                        </span>
                                        <span className="w-1/4 text-right text-dark">
                                            + {getFormattedPrice(el.price * el.quantity, true, currency)}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </div>)
                })}
                </div>
}
                {state.cart.items.filter(x => x.type === 'CONFIGURABLE' || x.type === 'VARIANT' || x.type === 'MONEY_VOUCHER').map((kit, index) => {
                    return (<div key={index}>
                        <div className="flex mt-2">
                            <span className="w-2/3 text-left text-dark">
                                {kit.quantity} {t('szt.')} {kit.name}
                                {kit.timeslot  && <span> <br/>{kit.timeslot.time} {moment(kit.timeslot.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}</span>}
                            </span>
                            <span className="w-1/3 text-right text-dark">
                                {kit.quantity > 1 && kit.quantity + "x" } {getFormattedPrice(kit.price, true, currency)}
                            </span>
                        </div>
                        {(kit.upsell?.length ?? 0) > 0 &&
                        <div className="pl-6 pr-2">
                            {kit.upsell?.map((el, index) => {
                                return (
                                    <div className="w-full flex" key={index}>
                                        <span className="w-3/4 text-left text-dark">
                                            + {el.name} ({getCartItemLabel(el, state.language).map((result, index) => (result))})
                                        </span>
                                        <span className="w-1/4 text-right text-dark">
                                            + {getFormattedPrice(el.price * el.quantity, true, currency)}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </div>)
                })}
                <div className={"mt-4"}/>
                {(discount?.value ?? 0) > 0 &&
                <div>
                    <div className="flex ">
                        <div className="text-lg font-bold text-left w-1/2">{t('Cena przed rabatem')}:</div>
                        <div className="text-lg font-bold text-right w-1/2">{getFormattedPrice(state.cart.basePrice, true, currency)}</div>
                    </div>
                 <div className="flex ">
                   <div className="text-lg font-bold text-left text-green w-full">
                        {t('Rabat')}: {discount?.type === 'PERCENT_COUPON' ? discount?.value + " %" : getFormattedPrice(discount?.value ?? 0, true, currency)}</div>
                        <div className=" text-lg font-bold text-right text-green w-full">
                         - {getFormattedPrice(state.cart.basePrice - state.cart.price, true, currency)}</div></div></div>}
                <div className="flex">
                    <div className="text-lg font-bold text-left w-1/2">{t('Suma')}:</div>
                    <div className="text-lg font-bold text-right w-1/2">{getFormattedPrice(state.cart.price, true, currency)}</div>
                </div>
                {!props.isDiscountDisabled &&
                <div className={"mt-4 max-w-100"}>
                    <span className={"cursor-pointer text-sm"}
                          onClick={() => setVoucherInputActive(!voucherInputActive)}>
                        {t('MAM KOD RABATOWY')}
                        <FiChevronDown className={classNames(
                            {
                                "inline-block transform duration-150 ease-in-out mb-1": true,
                                "rotate-180": voucherInputActive
                            })}/>
                    </span>
                    {
                        voucherInputActive &&
                        <div className={"mt-2"}>

                            <span className={"text-red"}>  </span>
                            <div className={"text-xs mb-1"}> {!discount && <span>{t('Wpisz kod')}</span>}</div>
                            <div>
                                <div className={"inline-block  align-top"} style={{width: "calc(100% - 6rem)"}}>
                                    <input className={"text-center w-full m-0 p-2 border-grey border hover:border-secondary rounded active:border-secondary focus:outline-none"}
                                           value={discountCode} onChange={e =>
                                        // @ts-ignore
                                        setDiscountCode(e.target.value)}
                                           disabled={discount != undefined}/>

                                </div>
                                <div className={"inline-block w-24 pl-2"}>
                                    <button className="p-2 inline-block bg-secondary text-white shadow rounded
                  hover:bg-white hover:text-secondary justify-center text font-bold mx-auto max-w-full border-2 border-secondary ml-2"
                                            onClick={processCouponCode}
                                    >{discount ? t("USUŃ") : t("DODAJ")}</button>
                                </div>
                            </div>
                            {
                                errorMessage &&
                                <div className={"text-red text-xs font-bold"}>
                                    {errorMessage ?
                                    errorMessage
                                    : <span>
                                        {t('Podany kod nie jest kodem rabatowym.')}
                                        <span className={"cursor-pointer"} onClick={props.openVoucherInput}>
                                            {t('Kliknij tutaj')}
                                        </span>
                                       {t(' aby wprowadzić kod vouchera podarunkowego')}</span>}
                                </div>
                            }
                               {
                                successMessage &&
                                <div className={"text-green text-xs font-bold"}>
                                    {successMessage}
                                </div>
                            }
                        </div>
                    }
                </div>}
            </div>
        </React.Fragment>
    );
};

export default ConfirmationSummary;
